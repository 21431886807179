<template>
	<div class="main">
		<div class="nav"></div>
		<div class="nav_btn">
			<div class="btn_left">
		
				<a href="/">
					<div class="btn_list">主页</div>
				</a>
				<router-link to="/data_index">
					<div class="btn_list listActive">数据中心</div>
				</router-link>
				
				<router-link to="/data_screen">
					<div class="btn_list">生长监测</div>
				</router-link>
			</div>

		</div>
		<div class="content">
			<div class="centerBox">
				<div class="baseBox centerMainBox1" style="height:70%">
					<div class="baseBoxLeft left">
						<div class="boxTitle">气象数据监测</div>
						<img src="../assets/main/img/left.png" alt="">
						<table class="equipment_table env_table">
							<tr>
								<td>指标</td>
								<td>标准值</td>
								<td>当前值</td>
								<td>预警</td>
							</tr>
							<tr>
								<td>温度</td>
								<td>19～26℃</td>
								<td>22℃</td>
								<td>正常</td>
							</tr>
							<tr>
								<td>湿度</td>
								<td>40%～60%</td>
								<td>42%</td>
								<td>正常</td>
							</tr>
							<tr>
								<td>风速</td>
								<td>0m/s~4m/s</td>
								<td>2m/s</td>
								<td>正常</td>
							</tr>

						</table>
						<div style="width:95%;height:50%;margin-left:2.5%" id="wind"></div>
					</div>
					<div class="baseBoxRight right" align="center">

						<!-- 地图 -->
						<!-- <div class="map"> -->
						<img class="mapImg" src="../assets/main/img/2d2.jpg">

						<!-- </div> -->
					</div>
				</div>

				<div class="baseBox centerMainBox2" style="height:30%">
					<img src="../assets/main/img/right3.png" alt="">
					<!-- 四个角描边 end  -->
					<div class="boxTitle3">二氧化碳浓度数据</div>
					<div class="boxTitle4">氧气浓度数据</div>

					<div class="boxRader-table" id="carbonDioxide"></div>
					<div class="boxRader-table-oxygen" id="oxygen"></div>
				</div>


			</div>
			<div class="leftBox right" style="width:22%">
				<div class="baseBox baseBox1" style="margin-bottom:15px;height:40%">
					<img src="../assets/main/img/right.png" alt="">
					<div class="boxTitle">空气质量监控</div>
					<div class="boxRader" id="airQuality"></div>
					<!-- <div style="position: relative; 
			width: 80%;
			height: 70%;" id="airQuality"></div> -->
					<!-- <div class="csbaseBox1">
							<div class="boxTitle">空气质量监控</div>

						</div> -->
				</div>
				<div class="baseBox baseBox2" style="margin-bottom:15px;height:29.5%">
					<img src="../assets/main/img/right2.png" alt="">
					<!-- 四个角描边 end -->
					<div class="boxTitle2">风力监控</div>
					<div class="boxRader" id="Progress"></div>
				</div>
				<div class="baseBox baseBox3" style="height:28.9%">
					<img src="../assets/main/img/right3.png" alt="">
					<!-- 四个角描边 end -->
					<div class="boxTitle2">土壤PH酸碱度</div>
					<div class="boxRader" id="mains"></div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import '../assets/main/css/reset.css';
	import '../assets/main/css/new_index.css';
	import echarts from 'echarts'
	export default {

		components: {

		},

		data() {
			return {

			}
		},
		filters: {

		},
		created() {
			//  this.tableHeight = window.innerHeight - 500
		},
		methods: { // 修改table tr行的背景色

			drawLine() {

				var Data = {
					times: ["2024-05-28 11:00:00", "2024-05-28 12:00:00", "2024-05-28 13:00:00", "2024-05-28 14:00:00",
						"2024-05-28 15:00:00", "2024-05-28 16:00:00", "2024-05-28 17:00:00", "2024-05-28 18:00:00",
						"2024-05-28 19:00:00", "2024-05-28 20:00:00", "2024-05-28 21:00:00", "2024-05-28 22:00:00"
					],
					timeData: ["08/00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
					windxData: ["东南", "北", "东南", "东", "东南", "东南", "西", "北", "南", "东南", "东南", "东南"],
					windsData: ["2.8/2", "1.4/1", "2.7/2", "3/2", "2.2/2", "1.4/1", "1.5/1", "1.9/2", "1.4/1", "1.8/2",
						"2/2",
						"3.4/3"
					],
					temData: [9, 11, 13, 12, 11, 9, 9],
					/* rainData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], */
					humData: [76, 76, 70, 62, 58, 53, 50],
					times1: ['2024.5.5', '2024.5.6', '2024.5.7', '2024.5.8', '2024.5.9', '2024.5.10', '2024.5.11']
				}

				var HYChart1 = echarts.init(document.getElementById('wind'));
				var HYChart2 = echarts.init(document.getElementById('Progress'));
				var HYChart3 = echarts.init(document.getElementById('mains'));
				var HYChart4 = echarts.init(document.getElementById('carbonDioxide'));
				var HYChart5 = echarts.init(document.getElementById('oxygen'));
				var HYChart6 = echarts.init(document.getElementById('airQuality'));

				var option;
				var option1;
				var option2;
				var option3;
				var option4;
				var option5;
				var option6;
				
				var colors = ['#FF6863', '#6EAB40', '#3BB5F5'];
				option = {
					color: colors,
					grid: {
						top: '17%',
						bottom: '20%',
						left: '15%',
						right: '15%'
					},

					tooltip: {
						trigger: 'axis'
					},
					legend: {
						textStyle: { //图例文字的样式
							color: '#fff',
							fontSize: 12
						},
						data: ['温度', '相对湿度(%)']
					},

					xAxis: {
						type: 'category',
						boundaryGap: false,
						axisLine: {
							show: true,
							lineStyle: {
								color: '#b0b0b0'
							}
						},
						data: Data.times1
					},
					yAxis: {
						type: 'value',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#4fe2ff'
							}
						},
					},
					series: [{
							name: '温度',
							type: 'line',
							stack: '总量',

							label: {
								show: true,
								position: 'top'
							},
							data: Data.temData
						},
						{
							name: '相对湿度(%)',
							type: 'line',
							stack: '总量',
							label: {
								show: true,
								position: 'top'
							},
							data: Data.humData
						}
					]
				};

				// 进度条
				var baifenbi = [63000, 153210, 37210, 43780].reverse();
				var grayBar = [1, 1, 1, 1, ];
				var zongjine = [500, 500, 500, 500];
				var xingm = ['云台大叶', '槠叶齐', '碧香早', '黄金茶'].reverse();
				// console.log(xingm.reverse())
				option2 = {
					/* title: {
					        text: '风力',
					        textStyle: { //图例文字的样式
					        	color: '#00aaff',
					        	fontSize: 14
					        },
					    }, */
					xAxis: {
						type: 'category',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#b0b0b0'
							}
						},
						data: Data.times1
					},
					grid: {
						left: '12%',
						right: '12%',
						bottom: '5%',
						top: '15%',
						containLabel: true
					},
					yAxis: {
						type: 'value',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#4fe2ff'
							}
						},
					},
					color: ['#ff5500'],
					series: [{
						data: [5, 4, 2, 3, 3, 2, 3],
						type: 'line',
						label: {
							show: true,
							position: 'top'
						},
						smooth: true
					}]
				};

				option3 = {

					xAxis: {
						type: 'category',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#b0b0b0'
							}
						},
						data: Data.times1
					},
					grid: {
						left: '12%',
						right: '12%',
						bottom: '5%',
						top: '15%',
						containLabel: true
					},
					yAxis: {
						min: 0,
						max: 12,
						type: 'value',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#4fe2ff'
							}
						},
					},
					color: ['#0bff64', '#F45E23'],
					series: [{
						label: {
							show: true,
							position: 'top'
						},
						data: [6.5, 6.4, 6.8, 6.7, 6.5, 6.4, 6.5],
						type: 'line'
					}]
				};

				option4 = {

					grid: {
						top: '17%',
						bottom: '20%',
						left: '25%',
						right: '10%'
					},
					xAxis: {
						type: 'category',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#b0b0b0'
							}
						},
						data: Data.times1
					},
					yAxis: {
						min: 0,
						max: 0.06,
						type: 'value',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#4fe2ff'
							}
						},
					},
					color: ['#0bff64', '#F45E23'],
					series: [{
						label: {
							show: true,
							position: 'top'
						},
						data: [0.038, 0.036, 0.037, 0.035, 0.037, 0.035, 0.036],
						type: 'line'
					}]
				};

				option5 = {

					grid: {
						top: '17%',
						bottom: '20%',
						left: '25%',
						right: '10%'
					},
					xAxis: {
						type: 'category',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#b0b0b0'
							}
						},
						data: Data.times1
					},
					yAxis: {
						min: 0,
						max: 40,
						type: 'value',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#ffbf00'
							}
						},
					},
					color: ['#ffff00', '#ff5500'],
					series: [{
						label: {
							show: true,
							position: 'top'
						},
						data: [20.9, 20.8, 21.0, 20.7, 20.8, 20.9, 20.8],
						type: 'line'
					}]
				};

				option6 = {
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						textStyle: { //图例文字的样式
							color: '#fff',
							fontSize: 12
						},
						data: ['PM2.5', 'CO', 'SO2', ]
					},
					color: ['#0efcff', '#aaaaff', '#F45E23'],
					grid: {
						left: '12%',
						right: '12%',
						bottom: '5%',
						top: '20%',
						containLabel: true
					},
					toolbox: {
						show: true,
						feature: {}
					},
					xAxis: {
						type: 'category',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#b0b0b0'
							}
						},
						data: Data.times1
					},
					yAxis: {
						type: 'value',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#42c600'
							}
						},
					},
					series: [{
							name: 'PM2.5',
							type: 'bar',
							label: {
								show: true,
								position: 'top'
							},
							data: [20, 19, 43, 22, 46, 63, 40],

						},
						{
							name: 'CO',
							type: 'bar',
							label: {
								show: true,
								position: 'top'
							},
							data: [0.5, 0.7, 1, 0.5, 0.8, 0.8, 0.8]
						},
						{
							name: 'SO2',
							type: 'bar',
							label: {
								show: true,
								position: 'top'
							},
							data: [7, 4, 8, 8, 7, 8, 8]
						}
					]
				};

				option && HYChart1.setOption(option);
				option2 && HYChart2.setOption(option2);
				option3 && HYChart3.setOption(option3);
				option4 && HYChart4.setOption(option4);
				option5 && HYChart5.setOption(option5);
				option6 && HYChart6.setOption(option6);

			}

		}, // methods

		mounted() {
			this.drawLine();
		}

	}
</script>

<style lang="scss">
	.boxRader {
		position: relative;
		width: 100%;
		height: 80%;
	}

	.boxRader-table {
		position: relative;
		width: 45%;
		height: 100%;
		float: left;
		margin-left: 40px;
		margin-top: -20px;
	}

	.boxRader-table-oxygen {
		position: relative;
		width: 45%;
		height: 100%;
		margin-right: 20px;
		float: right;
		margin-top: -20px;
	}

	.centerBox {
		width: 75%;
		margin-right: 15px;
		background: transparent;
	}

	.boxFont {
		font-size: 1.2vw;
	}

	.centerMainBox1 {
		width: 100%;
		height: 60.5%;
	}

	.centerMainBox2 {
		width: 100%;
		height: 38.5%;
		position: relative;
		margin-top: 15px;
	}

	.centerMainBox2 img {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.boxTitle2 {
		width: 100%;
		height: 10%;
		font-size: .6vw;
		text-align: center;
		line-height: 10%;
		margin-top: 1vw;
		color: #0efcff;
	}

	.boxTitle3 {
		width: 50%;
		height: 10%;
		font-size: .6vw;
		text-align: center;
		line-height: 10%;
		margin-top: 1vw;
		color: #0efcff;
	}

	.boxTitle4 {
		width: 75%;
		height: 10%;
		font-size: .6vw;
		text-align: right;
		/* float: right; */
		line-height: 10%;
		margin-top: -1.3vw;
		color: #0efcff;
	}


	.boxLis {
		width: 70%;
		height: 1.5vw;
		margin-left: 3vw;
		margin-top: 1vw;
		border-bottom: .02vw solid rgb(40, 229, 233);
	}

	.boxLis>li {
		width: 4vw;
		height: 1.4vw;
		display: block;
		background: rgba(40, 229, 233, .5);
		margin-right: .5vw;
		color: #fff;
		line-height: 1.4vw;
		text-align: center;
		cursor: pointer;
	}

	.mapImg {
		width: 80%;
		height: 110%;
		/* position: relative; */
		margin-top: 50px;
	}

	.active {
		border-bottom: .02vw solid #040A18;
		border-top: .02vw solid rgb(40, 229, 233);
		border-right: .02vw solid rgb(40, 229, 233);
		border-left: .02vw solid rgb(40, 229, 233);
	}

	.contList {
		position: relative;
		width: 70%;
		height: 7vw;
		margin: 1vw auto 0;

	}

	.content1 li {
		display: none;
	}

	.baseBox {
		width: 100%;
		border: none;
		background: none;
	}

	.boxTitle {
		font-size: .8vw;
		width: 38%;
		margin-top: 1vw;
		text-align: center;
	}

	.right {
		float: right;
	}

	.baseBoxLeft {
		width: 30%;
		height: 95%;
		position: relative;
	}

	.baseBoxRight {
		width: 68%;
		height: 75%;
	}

	.baseBoxLeft img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.maps {
		width: 100%;
		height: 95%;
		margin-top: 2%;
		background: pink;
	}

	.baseBox1,
	.baseBox2,
	.baseBox3 {
		position: relative;
	}

	.baseBox1 img,
	.baseBox2 img,
	.baseBox3 img {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.csbaseBox1 {
		position: relative;
		z-index: 999;
	}
</style>
<!-- 黄瑶 -->
<style>
	.liSpan {
		width: 100%;
		margin-top: 3.2%;
		text-align: center;
		color: rgb(40, 229, 233);
	}

	.liP {
		width: 66%;
		margin: .3vw auto 0;
		text-align: left;
		font-size: .5vw;
		color: rgb(40, 229, 233);
	}

	a:hover {
		text-decoration: none
	}

	.titleName {
		color: #0EFCFF;
	}

	/* 种植 */
	.plant {
		width: 90%;
		height: 5.5vw;
		margin: 2% auto 0;
		display: -webkit-flex;
		display: -moz-flex;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
	}

	.plant>li {
		width: 15%;
		height: 100%;
	}

	.jumps {
		width: 100%;
		height: 100%;
		background: rgba(0, 235, 255, .08);
		display: -webkit-flex;
		-webkit-flex-direction: column;
		-webkit-justify-content: space-between;
		display: -moz-flex;
		-moz-flex-direction: column;
		-moz-justify-content: space-between;
	}

	.jumps>div>img {
		width: .88vw;
		height: .88vw;
		float: right;
	}

	.jumps>p {
		width: 90%;
		font-size: 1.3vw;
		color: #fff;
		margin: auto;

	}

	.jumps>div {
		width: 90%;
		margin: 5% auto 0;
	}

	.jumps>div>span {
		font-size: .6vw;
		color: #0efcff;
		float: left;
	}

	.spans0 {
		width: 80%;
		height: 5%;
		background: #03DA8D;
	}

	.spans1 {
		width: 80%;
		height: 5%;
		background: #0efcff;
	}

	.spans2 {
		width: 80%;
		height: 5%;
		background: #FE0000;
	}

	.spans3 {
		width: 80%;
		height: 5%;
		background: #F78001;
	}

	.boxVideo {
		width: 100%;
		height: 100%;
	}

	.boxVideo video {
		width: 100%;
		height: 100%;

	}

	.baseDatas {
		width: 70%;
		height: 2vw;
		display: -webkit-flex;
		-webkit-justify-content: space-between;
		margin: 1vw 0 0 5vw;

	}

	.baseDatas li {
		width: 10%;
	}

	.baseDatas li a {
		color: #fff;
		font-size: .8vw;
	}

	.active2 a>span {
		color: rgb(40, 229, 233);
	}

	.baseDatas li a:hover>span {
		color: rgb(40, 229, 233);
	}

	.baseDatas li a:hover>.borderRight {
		border-left-color: rgb(40, 229, 233);
	}

	.tableDetalis {
		width: 92%;
		color: #fff;
		margin-left: 2vw;
	}

	.tableDetalis tr {
		height: 1.3vw;
		font-size: .8vw;
	}

	.tableDetalis tr>td {
		text-align: center;
	}

	.tableDetalis tr>td:nth-child(7) {
		text-align: right;
	}

	.borderRight {
		width: 0;
		height: 0;
		float: left;
		border: .4vw solid transparent;
		border-left-color: #fff;
		margin-top: .2vw;
	}

	.borderActive {
		border-left-color: rgb(40, 229, 233);
	}

	.equipment_table {
		width: 85%;
		height: 25%;
		margin: auto;
		text-align: center;
		color: #fff;
		font-size: .5vw;
	}

	.equipment_table tr:nth-child(1) {
		color: #0EFCFF;
	}

	.equipment_table tr {
		border: 1px solid#0EFCFF;
	}

	.equipment_table tr:nth-child(1) {
		font-size: .8vw;
	}

	.equipment_table img {
		width: 1vw;
	}

	.env_table {
		height: 35%;
		margin-top: 5%;
	}

	.env_table td {
		border: 1px solid#0EFCFF;
	}

	.env_table tr:nth-child(1) {
		background: rgba(14, 252, 255, .3);
	}

	.map img {
		width: 40%;
		margin-left: 10%;
	}

	.map img {
		width: 80%;
		margin-left: 10%;
	}

	.mapActived1 {
		position: absolute;
		top: 41.5%;
		left: 61%;
		width: 3.5vw;
		height: 5.5vw;
		border-radius: 90px;
		cursor: pointer;
	}

	.mapActived2 {
		position: absolute;
		top: 60%;
		right: 16%;
		width: 4.5vw;
		height: 2.5vw;
		cursor: pointer;
	}

	.mapActived3 {
		position: absolute;
		top: 57%;
		right: 9%;
		width: 2.5vw;
		height: 7vw;
		cursor: pointer;
	}

	.mapContent1 {
		position: absolute;
		height: 6vw;
		width: 8vw;
		left: 65%;
		top: 33%;
		background: rgba(255, 255, 255, .2);
	}

	.mapContent2 {
		position: absolute;
		height: 6vw;
		width: 8vw;
		left: 70%;
		top: 35%;
		background: rgba(255, 255, 255, .2);
		display: none;
	}

	.mapContent3 {
		position: absolute;
		height: 6vw;
		width: 8vw;
		right: 1%;
		top: 35%;
		background: rgba(255, 255, 255, .2);
		display: none;
	}


	.mapContentFont {
		width: 85%;
		margin-left: 12%;
		font-size: .6vw;
		color: #0efcff;
		letter-spacing: .05vw;
		margin-bottom: .3vw;
	}

	.mapContentFont:nth-child(1) {
		margin-top: .6vw;
	}

	.mapContentFont span {
		color: white;
	}

	/* lafite video样式 */
	.video-js {
		width: 100%;
		height: 100%;
	}

	.video-js:hover .vjs-big-play-button {
		background: none;
	}

	.video-js .vjs-big-play-button:hover {
		background: none;
	}

	.video-js .vjs-big-play-button {
		border: none;
		background: none;
	}
</style>
